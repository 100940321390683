import React, { useState } from 'react'
import { graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Menu from '../components/menu'
import Footer from '../components/footer'
import Seo from '../components/seo'
import SearchResults from '../components/search-results'
import { useFlexSearch } from 'react-use-flexsearch'
import '../styles/main.css'

const SearchPage = ({data, location}) => {
    const query = location?.state?.query
    const [searchQuery, setSearchQuery] = useState(query || '');
    //const [searchQuery, setSearchQuery] = useState('');

    const results = useFlexSearch(searchQuery, data.localSearchPosts.index, data.localSearchPosts.store)
    //console.log(unFlattenResults(results))
    const posts = searchQuery ? results : []
    //console.log(posts)
    return (
        <>
        <Seo/>
        <Container fluid="md">
            <Row>
            <Col>
                <Menu setSearchQuery={setSearchQuery}/>
            </Col>
            </Row>
            <div id ="content-row">

                <Row>
                    <h2>Resultados de búsqueda</h2>
                </Row>
                <SearchResults posts={posts} />

            </div>

            <Footer/>

        </Container>
        </>
    )
}



export const query = graphql`
  query {
    localSearchPosts {
        index
        store
      }
  }
`

export default SearchPage
/*
            
*/