
import React from "react"
import Row from 'react-bootstrap/Row'
import { Link} from "gatsby"
import {getDateSorted} from '../utils/utils'

const SearchResults = ({posts}) => {

    const allPosts = getDateSorted(posts)
    return (
        <>
        {allPosts.map((post) => {
            let slugString = ""
            if (post.id.includes("Posts")) slugString ="posts"
            if (post.id.includes("Stories")) slugString ="stories"

            return (
            <div id ="search-result-item">

            <Row>
            <h4><Link to={`/${slugString}/${post.slug}`}>{post.title}</Link></h4>
            </Row>
            <Row>
            <p>
                <i class="fas fa-calendar" id="card-icon"></i>{` `}{new Date(post.date).toLocaleDateString('es-mx',{year: 'numeric',month: 'long',day: 'numeric'})}{`  `}
                <i class="fas fa-user" id="card-icon"></i>{` `}{post.author}{`  `}
                <i class="fas fa-folder" id="card-icon"></i>{` `}{post.category} {`  `}
                <i class="fas fa-tag" id="card-icon"></i>{` `}{post.tags?.map((tag)=>(
                <>{tag.name}{`  `}</>
                ))}
            </p>
            </Row>

            </div>
            
        )
        })}
        </>
        
    )
}
export default SearchResults
